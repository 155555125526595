import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../../components/layout";
import Banner from "../../components/banner";
import IndividualPlan from "../../components/individualPlan";
import NumbersWithCount from "../../components/numbersWithCount";
import RecentNews from "../../components/recentNews";
import Slider from "../../components/slider";

import Image from "../../assets/img/hero-img.png";

const IndexPage = ({ data: lastPosts }) => {
  const lang = "en";

  return (
    <Layout
      seoTitle="Home"
      isHome={true}
      lang={lang}
      translationPL="/"
      translationDE="/de/"
    >
      <Banner
        type="home"
        title="On-time delivery <br/> of pallets <br/> in Europe"
        image={Image}
        lang={lang}
      />
      <IndividualPlan
        title="Customised production plans"
        text="PalettenWerk offers services in the design and production of special-purpose pallets, including large pallets, wooden platforms for transporting machinery or household appliances, and pallets that meet special conditions in terms of structural strength, load securing systems, and any other details of the manufacture of individual pallet components. "
        lang={lang}
      />
      <section className="offer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-40">Our services</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <p className="mb-80">
                PalettenWerk offer Euro-pallets (EPAL-pallets), chemical pallets
                (CP), and over 5,000 four-way and two-way entry pallet designs
                developed in accordance with technical standards or the
                customer’s specifications. PalettenWerk supplies pallets to
                companies from such industries as food, construction, chemical,
                pharmaceutical, brewing, and metals.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 offer__btn-holder">
              <Link
                className="btn"
                to="/en/our-services/"
                data-hover="Learn more"
              >
                Learn more
                <span className="btn-arrows"></span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <NumbersWithCount lang={lang} />
      <RecentNews lang={lang} lastPosts={lastPosts} />
      <Slider lang={lang} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    lastPosts: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { language: { code: { eq: EN } } }
      limit: 3
    ) {
      edges {
        node {
          id
          slug
          uri
          title
          content
          excerpt
          date
          language {
            code
            slug
            name
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;
